export const FORM_ENDPOINTS = {
    'login_form': '/auth/login',
    'changeGamePasswordForm': '/ajax/gamePassword/change',
    'changeEmail': '/ajax/email/change',
    'change_password': '/ajax/password/change',
    'formRegisterGame': '/register/gameAccount',
    'resetSkillTree': '/ajax/gameAction',
    'changeClassForm': '/ajax/gameAction',
    'changeNameForm': '/ajax/gameAction',
    'hideCharacterInfoForm': '/ajax/gameAction',
    'unstuckCharacterForm': '/ajax/gameAction',
    'clearPkForm': '/ajax/gameAction',
    'clearInventoryForm': '/ajax/gameAction',
    'resetStatsForm': '/ajax/gameAction',
    'exchangeCoinsForm': '/ajax/accountAction',
    'confirmPaymentForm': '/dashboard/payment/binance/simple/create',
};