import Swal from 'sweetalert2';

export class Messenger {
    static #defaultOptions = {
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    };

    static async notice(title, icon, text, duration = 2000, refresh = false) {
        let messages = [];

        // Caso 1: {error: {field: [messages]}}
        if (typeof text === 'object' && text.error && typeof text.error === 'object') {
            Object.entries(text.error).forEach(([field, errorMessages]) => {
                if (Array.isArray(errorMessages)) {
                    messages.push(...errorMessages);
                }
            });
        }
        // Caso 2: error: {field: [messages]}
        else if (typeof text === 'object') {
            Object.entries(text).forEach(([field, errorMessages]) => {
                if (Array.isArray(errorMessages)) {
                    messages.push(...errorMessages);
                }
            });
        }
        // Caso 3: Se já for um array
        else if (Array.isArray(text)) {
            messages = text;
        }
        // Caso 4: Se for uma string simples
        else {
            messages = [text];
        }

        const formattedText = messages.join('<br>');

        await Swal.fire({
            ...this.#defaultOptions,
            title,
            html: formattedText,
            icon,
            timer: duration
        });

        if (refresh) {
            window.location.reload();
        }
    }


    static async confirm(title, text, confirmText = 'Yes', cancelText = 'No') {
        const result = await Swal.fire({
            title,
            text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            reverseButtons: true
        });

        return result.isConfirmed;
    }

}