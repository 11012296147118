import {Messenger} from './Messenger.js';

export class HTTPClient {
    #isSending = false;
    #csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    #baseUrl = `${window.location.protocol}//${window.location.host}`;

    constructor() {
        if (!this.#csrfToken) {
            throw new Error('CSRF token not found');
        }
    }

    async sendRequest(url, method, data, refresh = true) {
        if (this.#isSending) return null;
        this.#isSending = true;

        try {
            const response = await fetch(url.startsWith('http') ? url : `${this.#baseUrl}${url}`, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': this.#csrfToken
                },
                body: method !== 'GET' ? JSON.stringify(data) : undefined
            });

            return await this.#handleResponse(response, refresh);
        } catch (error) {
            console.error('HTTP Client error:', error);
            return null;
        } finally {
            this.#isSending = false;
        }
    }

    async uploadFile(url, formData, refresh = true) {
        if (this.#isSending) return null;
        this.#isSending = true;

        try {
            const response = await fetch(url.startsWith('http') ? url : `${this.#baseUrl}${url}`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': this.#csrfToken
                },
                body: formData
            });

            return await this.#handleResponse(response, refresh);
        } catch (error) {
            console.error('File upload error:', error);
            await Messenger.notice('Error', 'error', 'File upload failed. Please try again.', 5000, refresh);
            return null;
        } finally {
            this.#isSending = false;
        }
    }

    async #handleResponse(response, refresh) {
        const result = await response.json();

        if (result.error || result.success) {
            const messageType = result.error ? 'error' : 'success';
            await Messenger.notice(
                messageType.charAt(0).toUpperCase() + messageType.slice(1),
                messageType,
                result[messageType],
                5000,
                refresh
            );
        }

        return result;
    }
}