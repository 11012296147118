export class TooltipManager {
    #loadingRequests = new Map();
    #currentTooltip = null;
    #baseUrl = `${window.location.protocol}//${window.location.host}`;
    #mouseOverTooltip = false;
    #hideTimeout = null;
    #mouseOverItem = false;
    #currentRequest = null;

    initialize() {
        this.#setupEventListeners();
    }

    #setupEventListeners() {
        // Captura a entrada do mouse
        document.body.addEventListener('mouseenter', (e) => {
            const tooltipElement = e.target.closest('.itemTooltipView');
            const tooltipRender = e.target.closest('#itemTooltipRender');

            if (tooltipRender) {
                this.#mouseOverTooltip = true;
                clearTimeout(this.#hideTimeout);
            } else if (tooltipElement) {
                this.#mouseOverItem = true;
                clearTimeout(this.#hideTimeout);
                this.#handleTooltipHover(tooltipElement, e);
            }
        }, true);

        // Captura a saída do mouse
        document.body.addEventListener('mouseleave', (e) => {
            const tooltipElement = e.target.closest('.itemTooltipView');
            const tooltipRender = e.target.closest('#itemTooltipRender');

            // Verifica se o mouse realmente saiu do elemento
            if (tooltipElement) {
                if (!tooltipElement.contains(e.relatedTarget)) {
                    this.#mouseOverItem = false;
                }
            } else if (tooltipRender) {
                if (!tooltipRender.contains(e.relatedTarget)) {
                    this.#mouseOverTooltip = false;
                }
            }

            if (!this.#mouseOverItem && !this.#mouseOverTooltip) {
                // Utiliza um delay para evitar cancelamentos prematuros
                this.#hideTimeout = setTimeout(() => {
                    this.hideTooltip();
                }, 150);
            }
        }, true);

        // Atualiza a posição do tooltip conforme o mouse se move
        document.body.addEventListener('mousemove', (e) => {
            if (this.#currentTooltip) {
                this.#updateTooltipPosition(e);
            }
        });
    }

    // Validação simples para o valor hex
    #isValidHex(hex) {
        return /^[0-9a-fA-F]+$/.test(hex);
    }

    async #handleTooltipHover(element, event) {
        if (!element) return;

        const hex = element.dataset.hex;
        if (!hex || !this.#isValidHex(hex)) {
            return;
        }

        // Se já existe uma requisição para o mesmo elemento, não faz nada
        if (this.#currentRequest && this.#currentTooltip === element) {
            return;
        }

        // Cancela requisição anterior, se houver
        if (this.#currentRequest) {
            this.#currentRequest.abort();
        }

        const controller = new AbortController();
        this.#currentRequest = controller;
        this.#currentTooltip = element;

        try {
            const response = await fetch(`${this.#baseUrl}/api/item/${hex}`, {
                signal: controller.signal
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();

            // Exibe o tooltip apenas se o mouse ainda estiver sobre o item
            if (this.#mouseOverItem && this.#currentTooltip === element) {
                this.#showTooltip(data, event);
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                // Requisição abortada – não é erro real
                return;
            }
            console.error('Error loading tooltip:', error);
            this.hideTooltip();
        } finally {
            if (this.#currentRequest === controller) {
                this.#currentRequest = null;
            }
        }
    }

    #showTooltip(content, event) {
        let tooltip = document.getElementById('itemTooltipRender');

        if (!tooltip) {
            tooltip = document.createElement('div');
            tooltip.id = 'itemTooltipRender';

            // Adiciona classes base para estilo e posicionamento
            tooltip.className = 'absolute bg-black bg-opacity-90 text-sm rounded-md p-3 text-center z-30';

            // Define estilos base iniciais
            Object.assign(tooltip.style, {
                position: 'fixed',
                zIndex: '9999',
                pointerEvents: 'auto',
                visibility: 'hidden', // Começa escondido até posicionar corretamente
                opacity: '0'
            });

            // Adiciona event listeners
            tooltip.addEventListener('mouseenter', () => {
                this.#mouseOverTooltip = true;
                clearTimeout(this.#hideTimeout);
            });

            tooltip.addEventListener('mouseleave', () => {
                this.#mouseOverTooltip = false;
                if (!this.#mouseOverItem) {
                    this.#hideTimeout = setTimeout(() => {
                        this.hideTooltip();
                    }, 150);
                }
            });

            document.body.appendChild(tooltip);
        }

        // Atualiza o conteúdo
        tooltip.innerHTML = content;

        // Remove a classe hidden e atualiza a posição
        tooltip.classList.remove('hidden');

        // Atualiza a posição primeiro
        this.#updateTooltipPosition(event);

        // Depois torna visível com uma pequena transição
        requestAnimationFrame(() => {
            tooltip.style.visibility = 'visible';
            tooltip.style.opacity = '1';
            tooltip.style.transition = 'opacity 0.1s ease-in-out';
        });
    }

    #updateTooltipPosition(event) {
        const tooltip = document.getElementById('itemTooltipRender');
        if (!tooltip || tooltip.classList.contains('hidden')) return;

        const targetElement = this.#currentTooltip;
        const tooltipRect = tooltip.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const padding = 10;
        const mouseOffset = 15; // Offset from mouse cursor
        let left, top;

        // Get mouse position
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        // Calculate initial position based on mouse
        left = mouseX + mouseOffset;
        top = mouseY + mouseOffset;

        // Check if tooltip would go outside the right edge of the screen
        if (left + tooltipRect.width > window.innerWidth - padding) {
            left = mouseX - tooltipRect.width - mouseOffset;
        }

        // Check if tooltip would go outside the bottom edge of the screen
        if (top + tooltipRect.height > window.innerHeight - padding) {
            top = mouseY - tooltipRect.height - mouseOffset;
        }

        // Ensure tooltip stays within screen bounds
        left = Math.max(padding, Math.min(left, window.innerWidth - tooltipRect.width - padding));
        top = Math.max(padding, Math.min(top, window.innerHeight - tooltipRect.height - padding));

        // If tooltip would overlap with the target element, adjust position
        const overlap = {
            x: left < targetRect.right && left + tooltipRect.width > targetRect.left,
            y: top < targetRect.bottom && top + tooltipRect.height > targetRect.top
        };

        if (overlap.x && overlap.y) {
            // Try positioning to the right of the target
            if (targetRect.right + tooltipRect.width + padding <= window.innerWidth) {
                left = targetRect.right + padding;
            }
            // If not possible, try to the left
            else if (targetRect.left - tooltipRect.width - padding >= 0) {
                left = targetRect.left - tooltipRect.width - padding;
            }
        }

    }

    hideTooltip() {
        const tooltip = document.getElementById('itemTooltipRender');
        if (tooltip) {
            tooltip.classList.add('hidden');
        }
        this.#currentTooltip = null;
        this.#mouseOverItem = false;
        this.#mouseOverTooltip = false;
        clearTimeout(this.#hideTimeout);

        // Cancela qualquer requisição pendente
        if (this.#currentRequest) {
            this.#currentRequest.abort();
            this.#currentRequest = null;
        }
    }

    destroy() {
        const tooltip = document.getElementById('itemTooltipRender');
        if (tooltip) {
            tooltip.remove();
        }
        this.#currentTooltip = null;
        this.#mouseOverItem = false;
        this.#mouseOverTooltip = false;
        clearTimeout(this.#hideTimeout);

        if (this.#currentRequest) {
            this.#currentRequest.abort();
            this.#currentRequest = null;
        }
    }
}
